<template>
  <CCard
    ><CRow>
      <CCol>
        <div v-if="serverErrors.length">
          <CAlert
            v-for="err in serverErrors"
            :key="err"
            v-model="showDismissibleAlert"
            color="danger"
            dismissible
          >
            {{ err }}
          </CAlert>
        </div>
        <validation-observer ref="formValidator">
          <form v-on:submit.prevent="assignTeam">
            <div class="row text-left">
              <div class="col-md-12">
                <ValidationProvider
                  name="Hardware Team"
                  vid="team_id"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="form-group">
                    <label>{{ form.team_type == 'qa'?'QA ':'Hardware ' }} Team</label>
                    <select
                      name="hardware_team"
                      v-model="form.team_id"
                      class="form-control"
                      required
                    >
                      <option value="">Select Team</option>
                      <option
                        v-for="team in teams"
                        :value="team.id"
                        :key="team.id"
                      >
                        {{ team.name }}
                      </option>
                    </select>
                  </div>

                  <p class="text-danger mt-2 pt-0">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>

            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </validation-observer>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ReassignTeam",
  props: {
    newTeam: Object,
  },
  data: () => {
    return {
      hardware_team: "",
      qa_team: "",
      form: {
        team_id: '',
        team_type: ''
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    assignTeam() {
        this.$store.dispatch("Settings/loading", true);
        this.axios
          .put(`/admin/tasks/${this.$route.params.id}/reassign-team`, this.form)
          .then(() => {
            this.$toastr.s('success', "Team Successfully re-assigned to task.", "Team Re-assigned");
            this.$store.dispatch("Settings/loading", false);
            this.$emit('closeReassignModal');
          }, (err) => {
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }
            this.$store.dispatch("Settings/loading", false);
          });
    },
  },
  computed: {
    ...mapGetters("Teams", ["teams"]),
  },
  mounted() {
    this.$store.dispatch("Teams/getTeamList", 50);
    this.form = {...this.newTeam}
    console.log(this.form);
  },
};
</script>

<style scoped lang="scss">
.card {
  border: none;
  padding: 0px !important;
}
</style>
