<template>
  <div id="printArea">
    <CRow>
      <CCol md="8">
        <h4 class="text-uppercase pb-4">Task Detail</h4>
      </CCol>
      <CCol md="4" class="text-right d-print-none">
        <button @click="refresh()" class="btn btn-primary mr-3" title="Refresh">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="#ffffff"
            width="26"
            height="26"
            version="1.1"
            id="lni_lni-reload"
            x="0px"
            y="0px"
            viewBox="0 0 64 64"
            style="enable-background: new 0 0 64 64"
            xml:space="preserve"
          >
            <g>
              <path
                d="M7.4,28.5c0.3,0,0.6,0,0.8-0.1l11.1-3.9c0.9-0.3,1.4-1.3,1.1-2.2c-0.3-0.9-1.3-1.4-2.2-1.1l-6.7,2.4   c3.3-9.1,12-15.3,22.1-15.3c10.7,0,20.1,7.1,22.7,17.4c0.2,0.9,1.2,1.5,2.1,1.3c0.9-0.2,1.5-1.2,1.3-2.1c-3-11.8-13.8-20-26.1-20   c-12,0-22.4,7.7-25.8,18.9l-3.1-8.7c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.2l3.8,10.9C5.5,27.9,6.5,28.5,7.4,28.5z"
              />
              <path
                d="M62.6,49.9l-4.1-10.8c-0.2-0.6-0.7-1.1-1.3-1.3c-0.6-0.2-1.2-0.3-1.8,0l-11,4.2c-0.9,0.3-1.4,1.4-1,2.3   c0.3,0.9,1.4,1.4,2.3,1l6.8-2.6c-3.8,7.9-11.9,13.1-21.1,13.1c-10.1,0-19-6.3-22.1-15.7C8.9,39.2,7.9,38.7,7,39   c-0.9,0.3-1.4,1.3-1.1,2.2C9.5,52,19.7,59.3,31.3,59.3c11,0,20.8-6.5,24.8-16.4l3.2,8.3c0.3,0.7,0.9,1.1,1.6,1.1   c0.2,0,0.4,0,0.6-0.1C62.5,51.8,63,50.8,62.6,49.9z"
              />
            </g>
          </svg>
        </button>
        <CButton @click="print()" color="info" shape="pill">Print</CButton>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="4">
        <CCard class="p-5" v-if="task.customer_account">
          <CRow>
            <CCol class="avatar-container">
              <img
                class="mb-2"
                name="cil-user"
                :src="getImgUrl('user.png')"
                alt="User Logo"
              />
            </CCol>

            <CCol class="mt-3">
              <h4>
                {{
                  task.customer_account.first_name
                }}
              </h4>
              <p>
                Account No #<router-link
                  :to="`/consumers/${task.customer_account.id}`"
                  >{{ task.customer_account.account_number }}</router-link
                >
              </p>
            </CCol>
          </CRow>

          <ul class="customer-detail-list">
            <li>
              <p>Category:</p>
              <p>{{ task.customer_account.category }}</p>
            </li>
            <li>
              <p>Class:</p>
              <p>{{ task.customer_account.class }}</p>
            </li>
            <li>
              <p>MI:</p>
              <p>{{ task.customer_account.meter_inspector && task.customer_account.meter_inspector.mi_code }}</p>
            </li>
            <li>
              <p>Mobile No:</p>
              <p>{{ task.customer_account.mobile_number }}</p>
            </li>
            <li>
              <p>Email:</p>
              <p>{{ task.customer_account.email }}</p>
            </li>
            <li>
              <p>Ward No:</p>
              <p>{{ task.customer_account.ward_number }}</p>
            </li>
            <li>
              <p>Address 1:</p>
              <p>{{ task.customer_account.address }}</p>
            </li>
            <li>
              <p>Address 2:</p>
              <p>{{ task.customer_account.address2 }}</p>
            </li>
          </ul>
        </CCard>

        <Map
          class="d-print-none"
          v-if="
              task.meter &&
              task.meter.latitude &&
              task.meter.longitude
          "
          :name="task.meter.meter_number"
          :meter="task.meter"
        />
      </CCol>
      <CCol sm="8">
        <CRow>
          <CCol sm="12">
            <CCard>
              <div class="row mb-3">
                <div class="col-md-7">
                  <h4>Task: {{ task.uid }}</h4>
                </div>
                <div class="col-md-5 text-right">
                  <h4 class="text-primary">
                    Total Duration: {{ task.total_duration }}
                  </h4>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <!-- <th class="font-weight-bold">Task ID</th> -->
                      <th class="font-weight-bold">Name</th>
                      <th class="font-weight-bold">
                        Meter <br />
                        Serial <br />
                        No
                      </th>
                      <th class="font-weight-bold">Type</th>
                      <th class="font-weight-bold">Status</th>
                      <th class="font-weight-bold">Description</th>
                      <th class="font-weight-bold">
                        Completed <br />
                        At
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="task">
                    <tr class="text-center">
                      <!-- <td>{{ task.uid }}</td> -->
                      <td>{{ task.name }}</td>
                      <td>
                        <router-link
                          :to="`/meters/${task.meter.id}`"
                          v-if="
                            task.meter
                          "
                          >{{ task.meter.hardware_serial_no }}
                        </router-link>
                        <span v-else>N/A</span>
                      </td>
                      <td>{{ task.type }}</td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            task.status === 'PENDING'
                              ? 'badge-warning'
                              : task.status === 'task_INSTALLED'
                              ? 'badge-primary'
                              : 'badge-success'
                          "
                          >{{ task.status }}</span
                        >
                      </td>
                      <td>{{ task.description }}</td>
                      <td>
                        {{
                          task.completed_at
                            ? dateFormat(task.completed_at)
                            : "N/A"
                        }}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CCard>
              <h4 class="pb-3">Assigned Team Status</h4>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th class="font-weight-bold">Name</th>
                      <th class="font-weight-bold">Type</th>
                      <!-- <th class="font-weight-bold">Location</th> -->
                      <th class="font-weight-bold">Status</th>
                      <!-- <th class="font-weight-bold">Notes</th> -->
                      <th class="font-weight-bold">Started At</th>
                      <th class="font-weight-bold">Completed At</th>
                      <th class="font-weight-bold">Duration</th>
                      <th class="font-weight-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="task">
                    <tr
                      class="text-center"
                      v-for="(team, index) in task.teams"
                      :key="index"
                    >
                      <td>{{ team.name }}</td>
                      <td>{{ team.type }}</td>
                      <!-- <td>{{ team.location }}</td> -->

                      <td>
                        <span
                          class="badge"
                          :class="
                            team.team_status === 'PENDING'
                              ? 'badge-warning'
                              : team.team_status === 'INACTIVE'
                              ? 'badge-danger'
                              : 'badge-success'
                          "
                          >{{ team.team_status }}</span
                        >
                      </td>
                      <!-- <td>{{ team.notes }}</td> -->
                      <td>
                        {{
                          team.started_at ? dateFormat(team.started_at) : "N/A"
                        }}
                      </td>
                      <td>
                        {{
                          team.team_completed_at
                            ? dateFormat(team.team_completed_at)
                            : "N/A"
                        }}
                      </td>
                      <td>{{ team.team_duration }}</td>
                      <td>
                        <a href="javascript:void(0)" class="btn btn-info btn-sm" @click="showTaskDetailModal(team)">More</a> &nbsp;
                        <a v-if="team.team_status == 'INACTIVE' || team.team_status == 'PENDING'" href="javascript:void(0)" class="btn btn-danger btn-sm" @click="displayReassignModal(team)">Edit</a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CCard>
              <h4 class="pb-3">Task Logs</h4>
              <div class="table-responsive">
                <div
                  v-if="task.task_logs && task.task_logs.length"
                  class="log-list"
                >
                  <div v-for="log in task.task_logs" :key="log.id">
                    <span class="pr-2"
                      >{{ log.created_at ? dateFormat(log.created_at) : "N/A" }}
                    </span>
                    {{ log.description }}
                    ({{ log.user_name }})
                  </div>
                </div>
                <div v-else>
                  <h5 class="text-center">Data Not Available.</h5>
                </div>
              </div>
            </CCard>
          </CCol>
        </CRow>
      </CCol>

      <CModal
        :title="'Task Details of '+ selectedTeamTask.name +' (Team)'"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="showDetailModal"
        size="xl"
      >
        <TaskDetailModal
          @closeDetailModal="closeTaskDetailModal"
          :teamTask="selectedTeamTask"
        ></TaskDetailModal>
      </CModal>

      <CModal
        title="Reassign Team"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="showReassignModal"
      >
        <ReassignTeam
          v-if="showReassignModal"
          @closeReassignModal="closeTaskReassignModal"
          :newTeam="newTeam"
        ></ReassignTeam>
      </CModal>

    </CRow>
  </div>
</template>

<script>
import TaskDetailModal from "./partials/TaskDetailModal";
import ReassignTeam from "./partials/ReassignTeam";
const Map = () =>
  import(/* webpackChunkName: "MeterMap" */ "../meter/partial/Map");
import { mapGetters } from "vuex";

export default {
  name: "TaskView",
  components: {
    Map,
    TaskDetailModal,
    ReassignTeam
  },
  data: () => {
    return {
      taskId: Number,
      reEvent: {
        meter_installation: "Meter installation task created by",
        ASSIGNED_HARDWARE_TEAM: "Hardware team assigned by",
        ASSIGNED_QA_TEAM: "QA team assigned by",
        ACCEPTED: "Task done",
        COMPLETED: "Task completed",
        STARTED: "Task started",
      },
      updateStatusModal: false,
      deletingState: false,
      loadingState: false,
      serverErrors: [],
      showDismissibleAlert: false,
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Tasks", path:"/tasks", isActive: false}, {text:"View", path:"", isActive: true}],
      showDetailModal: false,
      selectedTeamTask: {} ,
      showReassignModal: false,
      newTeam: {}
    };
  },
  computed: {
    ...mapGetters("Task", ["task"]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    showTaskDetailModal(teamTask) {
      this.selectedTeamTask = teamTask;
      this.showDetailModal = true;
    },
    closeTaskDetailModal() {
      this.selectedTeamTask = {};
      this.showDetailModal = false;
    },
    displayReassignModal(item) {
      this.newTeam = {team_type: item.type, team_id: item.id}
      this.showReassignModal = true;
    },
    closeTaskReassignModal() {
      this.newTeam = {};
      this.showReassignModal = false;
      this.refresh();
    },
    refresh() {
      this.$store.dispatch("Task/getTask", this.taskId);
    },
    getImgUrl(pic) {
      return require("../../assets/images/" + pic);
    },
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <title>Task Detail - ${this.task.uid}</title>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener("load", () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
        }, 200);
      });
    },
  },
  watch: {
    $route(to, from) {
      this.taskId = this.$route.params.id;
      this.$store.dispatch("Task/getTask", this.taskId);
    },
  },
  mounted() {
    this.taskId = this.$route.params.id;
    this.$store.dispatch("Task/getTask", this.taskId);
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.update-status-btn-width {
  width: 100%;
}

.hide-footer >>> footer {
  display: none;
}

.custom-close-button >>> button {
  color: aliceblue;
}
.hide-footer >>> footer {
  display: none;
}
.card {
  border: none;
  padding: 30px 20px !important;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}

.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.custom-width {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.log-list {
  border: 1px solid #dadada;
  background-color: #eff0f5;
  border-radius: 4px;
  padding: 15px;
  position: relative;
  font-size: 13px;
  color: #212121;
  word-break: break-word;
  div {
    line-height: 2;
  }
  span {
    color: #9e9e9e;
  }
}
.avatar-container {
  max-width: 100px;
}
.customer-detail-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  li {
    width: 100%;
    float: left;
    p {
      float: left;
    }
    p {
      &:first-of-type {
        width: 35%;
        max-width: 100px;
        font-weight: 600;
      }
      &:last-of-type {
        width: 65%;
      }
    }
  }
}

div {
  .row {
    .card {
      p {
        color: #000;
      }
      .row {
        p {
          color: #5e76e7;
        }
      }
    }
  }
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
