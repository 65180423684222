<template>
  <CRow v-cloak>
    <CCol sm="12">      
      <ul>
        <li>Photo:</li>
        <li>
          <a
            v-if="teamTask.photo_url"
            :href="teamTask.photo_url"
            target="_blank"
            >Click here to View</a
          >
          <span v-else>N/A</span>
        </li>
        <li>Checklists:</li>
        <li>
          <ol v-if="teamTask.checklists && teamTask.checklists.length">
            <li v-for="(checklist, index) in teamTask.checklists" :key="index">
              {{ checklist }}
            </li>
          </ol>
          <span v-else>No checklists</span>
        </li>
        <li>Notes:</li>
        <li>{{ teamTask.notes }}</li>
      </ul>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "TaskDetailModal",
  props: {
    teamTask: Object,
  },
};
</script>

<style scoped lang="scss">
ul {
  padding: 25px 25px;
  list-style-type: none;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(174,174,174,1);
-moz-box-shadow: 0px 0px 8px 0px rgba(174,174,174,1);
box-shadow: 0px 0px 8px 0px rgba(174,174,174,1);
  border-radius: 10px;
  width: 100%;
  float: left;
  li {    
    float: left;
    &:nth-child(odd) {
      font-weight: 600;
      width: 14%;  
      margin-bottom: 15px;    
    }
    &:nth-child(even) {      
      width: 86%;    
      margin-bottom: 15px;   
      ol {    
        padding-left: 15px;    
        li {          
          float: none;    
          font-weight: normal;    
          width: 100%;        
        }
      }
    }
  }
}
</style>
